import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Skill Practice: Turkish Get Ups`}</p>
    <p>{`Skill Practice: Pullups/Chin Ups (Jumping/Assisted/Kipping/Strict/CTB,
etc)`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Half Murph`}</em></strong></p>
    <p>{`1/2 Mile Run`}</p>
    <p>{`50 Pullups`}</p>
    <p>{`100 Pushups (y)`}</p>
    <p>{`150 Squats`}</p>
    <p>{`1/2 Mile Run`}</p>
    <p>{`for time.`}</p>
    <p>{`*`}{`partition the pullups, pushups & squats as needed.`}</p>
    <p><strong parentName="p">{`*`}{`The Ville will close after the 4:30 class next Friday for Oaks.  `}</strong></p>
    <p><strong parentName="p">{`*`}{`East will be closed on Saturday, Derby Day.  We will have one class
at the Ville at 10:00am.  All are invited so bring a friend!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      